import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { 
  logEvent, 
  logSocialClick, 
  logDownload, 
  logEmailClick, 
  logButtonClick 
} from '../utils/analytics';
import { words, ideas, userNames, useNotifications } from '../config/wordLists';
import UserFlow from '../pages/userflow';
import FAQ from '../pages/FAQ';
import { Link } from 'react-router-dom'
import Social from '../pages/Social';


const PromoBanner = styled.div`
  background-color: #000000;
  color: white;
  padding: 0.5rem 1rem;
  font-size: 0.7rem;
  text-align: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;

const HomeContainer = styled.div`
  max-width: 1500px;
  margin: 20px auto 2rem auto;
  padding: 0 2rem;
  
  @media (max-width: 768px) {
    margin-top: 80px;
    padding: 0 1rem;
  }
`;

const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const DemoVideoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem;
  }
`;

const LeftSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2.2rem;
  align-items: flex-start;

  @media (max-width: 768px) {
    gap: 1rem;
    text-align: center;
    align-items: center;
  }
`;

const MainTitle = styled.h1`
  font-size: 2.5rem;
  line-height: 1.5;
  color: white;

  @media (max-width: 768px) {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    text-align: center;
  }
`;

const Subtitle = styled.h2`
  font-size: 1.5rem;
  line-height: 1.4;
  color: #FFD700;
  margin-top: -1rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    text-align: center;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    padding: 0 1rem;
  }
`;

const AppStoreButton = styled.a`
  display: inline-block;
  background: black;
  border: 2px solid white;
  border-radius: 50px;
  padding: 0.5rem 2rem;
  text-decoration: none;
  color: white;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 0.3s ease;
  
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
  }
  
  img {
    height: 35px;
  }
  
  @media (max-width: 768px) {
    padding: 0.5rem 1.5rem;
  }
`;

const DemoSection = styled.div`
  flex: 1;
  width: 50%;
  padding: 0.5rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

const ARFeatures = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin: 3rem 0;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

const FeatureCard = styled.div`
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-10px);
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`;

const FeatureIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 1rem;
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #FFD700;
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
  line-height: 1.6;
`;

const InvestorSection = styled.div`
  margin: 4rem 0;
  text-align: center;
`;

const InvestorTitle = styled.h2`
  font-size: 2.2rem;
  margin-bottom: 1.5rem;
  color: #FFD700;
`;

const InvestorDescription = styled.p`
  font-size: 1.3rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto 2rem;
`;

const InvestorButton = styled(Link)`
  display: inline-block;
  background-color: #FFD700;
  color: black;
  padding: 1rem 2rem;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.3s ease;
  
  &:hover {
    transform: scale(1.05);
    background-color: #FFC107;
  }
`;

const CTASection = styled.div`
  text-align: center;
  padding: 2rem 1rem;
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(255,215,0,0.1) 100%);

  @media (max-width: 768px) {
    padding: 1.5rem 1rem;
  }
`;

const CTATitle = styled.h2`
  color: white;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const CTAButton = styled(Link)`
  display: inline-block;
  background-color: #FFD700;
  color: black;
  font-size: 1.3rem;
  font-weight: bold;
  padding: 1rem 2rem;
  border-radius: 50px;
  text-decoration: none;
  transition: all 0.3s ease;
  border: 2px solid #FFD700;
  animation: pulse 2s infinite;

  &:hover {
    background-color: #FFC500;
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(255, 215, 0, 0.5);
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 215, 0, 0.4);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(255, 215, 0, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 215, 0, 0);
    }
  }

  @media (max-width: 768px) {
    font-size: 1.1rem;
    padding: 0.8rem 1.5rem;
    width: 90%;
    max-width: 300px;
    margin: 0 auto;
    display: block;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 2rem auto;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  border: 2px solid #FFFFFF;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }

  @media (max-width: 768px) {
    margin: 1rem auto;
  }
`;

const NotificationPopup = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: rgba(0, 0, 0, 0.9);
  border: 2px solid #FFFFFF;
  border-radius: 10px;
  padding: 15px 20px;
  color: white;
  font-size: 0.9rem;
  z-index: 1000;
  animation: slideIn 0.5s ease-out, fadeOut 0.5s ease-in 2.5s forwards;
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 300px;
  box-shadow: 0 4px 6px rgba(255, 105, 180, 0.1);

  @keyframes slideIn {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @media (max-width: 768px) {
    right: 10px;
    bottom: 10px;
    max-width: 250px;
    font-size: 0.8rem;
    padding: 12px 15px;
  }
`;

const NotificationIcon = styled.div`
  width: 40px;
  height: 40px;
  background-color: #FFFFFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
`;

function Home() {
  const {
    notification,
    showAppButton
  } = useNotifications(userNames);

  const handleDownloadClick = () => {
    logEvent('Button', 'Download Click', 'App Store Download');
  };

  const handleSocialClick = (network) => {
    logEvent('Social Media', 'Click', network);
  };

  return (
    <>
      <HomeContainer>
        <DemoVideoContainer>
          <LeftSection>
            <MainTitle>
              Discover Interactive AR Characters in the Real World with Glimpse
            </MainTitle>
            <Subtitle>
              Like Pokémon GO, but with interactive augmented reality figures you can talk to
            </Subtitle>
            <ButtonGroup>
              <AppStoreButton 
                href="https://apps.apple.com/mt/app/glimpse-valletta/id6736998656" 
                target="_blank"
                onClick={handleDownloadClick}
              >
                <span>Try our Beta App</span>
              </AppStoreButton>
            </ButtonGroup>
          </LeftSection>
          
          <DemoSection>
            <VideoContainer>
              <iframe
                src="https://www.youtube.com/embed/_JD3gw_hEt0"
                title="Demo Video"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </VideoContainer>
          </DemoSection>
        </DemoVideoContainer>
        
        <ARFeatures>
          <FeatureCard>
            <FeatureIcon>🌎</FeatureIcon>
            <FeatureTitle>Explore the World</FeatureTitle>
            <FeatureDescription>
              Find AR characters in real-world locations and interact with them through your smartphone camera.
            </FeatureDescription>
          </FeatureCard>
          
          <FeatureCard>
            <FeatureIcon>💬</FeatureIcon>
            <FeatureTitle>Talk to Characters</FeatureTitle>
            <FeatureDescription>
              Unlike Pokémon GO, our characters can hold real conversations with you using advanced AI technology.
            </FeatureDescription>
          </FeatureCard>
          
          <FeatureCard>
            <FeatureIcon>🏛️</FeatureIcon>
            <FeatureTitle>Learn About Places</FeatureTitle>
            <FeatureDescription>
              Interact with historical figures and local celebrities that teach you about the places you visit.
            </FeatureDescription>
          </FeatureCard>
          
          <FeatureCard>
            <FeatureIcon>🎮</FeatureIcon>
            <FeatureTitle>Gamified Experience</FeatureTitle>
            <FeatureDescription>
              Complete quests, collect characters, and unlock special content as you explore.
            </FeatureDescription>
          </FeatureCard>
        </ARFeatures>
        
        <InvestorSection>
          <InvestorTitle>We're Raising Our Pre-Seed Round</InvestorTitle>
          <InvestorDescription>
            After successfully building and market testing our AR application in Malta, we're now raising funds to expand our platform globally and bring interactive AR experiences to users worldwide.
          </InvestorDescription>
          <InvestorButton to="/investors">Investor Information</InvestorButton>
        </InvestorSection>
      </HomeContainer>

      {notification && (
        <NotificationPopup>
          <NotificationIcon>👤</NotificationIcon>
          <div>
            <strong>{notification}</strong> just downloaded our Beta App!
          </div>
        </NotificationPopup>
      )}

      <CTASection>
        <CTATitle>Ready to experience the future of AR?</CTATitle>
        <CTAButton 
          href="https://apps.apple.com/mt/app/glimpse-valletta/id6736998656"
          target="_blank"
          onClick={handleDownloadClick}
        >
          Try our Beta App
        </CTAButton>
        <p style={{ fontSize: '1.1rem', marginTop: '1.5rem', color: 'white' }}>
          Contact us: <a href="mailto:michael@glimpse.wiki" style={{ color: '#FFD700', textDecoration: 'none' }}>michael@glimpse.wiki</a>
        </p>
      </CTASection>

      <Social />
    </>
  );
}

export default Home;