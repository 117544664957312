import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const InvestorContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  color: white;
`;

const Hero = styled.div`
  text-align: center;
  margin-bottom: 3rem;
`;

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #FFD700, #FFA500);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2rem;
  max-width: 800px;
  margin: 0 auto 2rem;
`;

const Section = styled.section`
  margin-bottom: 4rem;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #FFD700;
  border-bottom: 2px solid rgba(255, 215, 0, 0.3);
  padding-bottom: 0.5rem;
`;

const SectionContent = styled.div`
  font-size: 1.1rem;
  line-height: 1.6;
`;

const Stats = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
`;

const StatCard = styled.div`
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 1.5rem;
  text-align: center;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const StatNumber = styled.h3`
  font-size: 2.5rem;
  color: #FFD700;
  margin-bottom: 0.5rem;
`;

const StatLabel = styled.p`
  font-size: 1rem;
`;

const TeamSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
`;

const TeamMember = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const TeamMemberPhoto = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  margin-bottom: 1rem;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const TeamMemberName = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`;

const TeamMemberRole = styled.p`
  font-size: 1rem;
  color: #FFD700;
  margin-bottom: 0.5rem;
`;

const TeamMemberBio = styled.p`
  font-size: 0.9rem;
  line-height: 1.5;
`;

const CTASection = styled.div`
  text-align: center;
  margin: 4rem 0;
  padding: 3rem;
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(255,215,0,0.1) 100%);
  border-radius: 10px;
`;

const CTATitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #FFD700;
`;

const CTADescription = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
`;

const TimelineContainer = styled.div`
  position: relative;
  max-width: 800px;
  margin: 2rem auto;
  
  &::after {
    content: '';
    position: absolute;
    width: 4px;
    background-color: rgba(255, 215, 0, 0.3);
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -2px;
  }
  
  @media (max-width: 768px) {
    &::after {
      left: 31px;
    }
  }
`;

const TimelineItem = styled.div`
  padding: 10px 40px;
  position: relative;
  width: 50%;
  
  &:nth-child(odd) {
    left: 0;
  }
  
  &:nth-child(even) {
    left: 50%;
  }
  
  @media (max-width: 768px) {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
    
    &:nth-child(odd), &:nth-child(even) {
      left: 0;
    }
  }
`;

const TimelineContent = styled.div`
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
  &::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: white;
    border: 4px solid #FFD700;
    border-radius: 50%;
    top: 20px;
    
    ${TimelineItem}:nth-child(odd) & {
      right: -60px;
    }
    
    ${TimelineItem}:nth-child(even) & {
      left: -60px;
    }
  }
  
  @media (max-width: 768px) {
    &::after {
      left: -45px;
    }
  }
`;

const TimelineDate = styled.span`
  color: #FFD700;
  font-weight: bold;
`;

function Investors() {
  return (
    <InvestorContainer>
      <Hero>
        <Title>Investor Relations</Title>
        <Subtitle>
          Join us on our mission to transform how people interact with the world through augmented reality
        </Subtitle>
      </Hero>
      
      <Section>
        <SectionTitle>Our Vision</SectionTitle>
        <SectionContent>
          <p>
            Glimpse is revolutionizing how people experience the world around them through augmented reality. 
            Similar to Pokémon GO, but with interactive characters powered by AI that users can have 
            meaningful conversations with, Glimpse creates immersive experiences that blend the digital 
            and physical worlds.
          </p>
          <p>
            Our platform enables people to discover virtual characters in real-world locations, 
            learn about the places they visit, and engage with content in an entirely new way. 
            From historical figures to local guides, our AR characters create memorable experiences 
            that educate, entertain, and connect.
          </p>
        </SectionContent>
      </Section>
      
      <Section>
        <SectionTitle>Market Opportunity</SectionTitle>
        <SectionContent>
          <p>
            The global AR market is projected to grow from $20.35 billion in 2024 to $175.2 billion by 2032, 
            at a CAGR of 38.2%. Location-based entertainment and education are among the fastest-growing 
            segments within this market.
          </p>
          <p>
            By combining the viral, location-based gameplay mechanics that made Pokémon GO a worldwide 
            phenomenon with conversational AI and rich, educational content, Glimpse is positioned at 
            the intersection of several explosive growth areas in technology.
          </p>
        </SectionContent>
        
        <Stats>
          <StatCard>
            <StatNumber>$175B+</StatNumber>
            <StatLabel>Projected AR Market by 2032</StatLabel>
          </StatCard>
          <StatCard>
            <StatNumber>38.2%</StatNumber>
            <StatLabel>CAGR of AR Market</StatLabel>
          </StatCard>
          <StatCard>
            <StatNumber>1B+</StatNumber>
            <StatLabel>AR-Capable Devices Worldwide</StatLabel>
          </StatCard>
          <StatCard>
            <StatNumber>5M+</StatNumber>
            <StatLabel>Estimated Tourism Users by 2026</StatLabel>
          </StatCard>
          <StatCard>
            <StatNumber>$60B+</StatNumber>
            <StatLabel>US AR Market by 2030</StatLabel>
          </StatCard>
        </Stats>
      </Section>
      
      <Section>
        <SectionTitle>Our Traction & Milestones</SectionTitle>
        <SectionContent>
          <p>
            We've successfully developed and launched our first application in Valletta, Malta, 
            which has been a perfect test market for our location-based AR experiences. The app is 
            available as a Beta App and has received positive user feedback.
          </p>
        </SectionContent>
        
        <TimelineContainer>
          <TimelineItem>
            <TimelineContent>
              <h3>Concept Development</h3>
              <TimelineDate>Q1 2024</TimelineDate>
              <p>Initial concept development and market research</p>
            </TimelineContent>
          </TimelineItem>
          
          <TimelineItem>
            <TimelineContent>
              <h3>MVP Development</h3>
              <TimelineDate>Q2-Q3 2024</TimelineDate>
              <p>Development of minimum viable product with core AR functionality</p>
            </TimelineContent>
          </TimelineItem>
          
          <TimelineItem>
            <TimelineContent>
              <h3>Valletta App Launch</h3>
              <TimelineDate>Q4 2024</TimelineDate>
              <p>Successfully launched our first app focused on Valletta, Malta</p>
            </TimelineContent>
          </TimelineItem>
          
          <TimelineItem>
            <TimelineContent>
              <h3>Market Testing</h3>
              <TimelineDate>Q1 2025</TimelineDate>
              <p>Extensive user testing and feedback collection</p>
            </TimelineContent>
          </TimelineItem>
          
          <TimelineItem>
            <TimelineContent>
              <h3>Fundraising</h3>
              <TimelineDate>Q2 2025</TimelineDate>
              <p>Opening pre-seed round to fund expansion</p>
            </TimelineContent>
          </TimelineItem>
        </TimelineContainer>
      </Section>
      
      <Section>
        <SectionTitle>Market Testing Results</SectionTitle>
        <SectionContent>
          <p>
            Our initial launch in Valletta has provided valuable data and user insights:
          </p>
          <ul>
            <li><strong>User Engagement:</strong> Average session time of 18 minutes, significantly higher than industry average</li>
            <li><strong>Retention:</strong> 35% 30-day retention rate, compared to industry average of 4%</li>
            <li><strong>Tourist Adoption:</strong> 22% of tourists who heard about the app downloaded it</li>
            <li><strong>Educational Impact:</strong> 87% of users reported learning something new about Valletta</li>
            <li><strong>User Satisfaction:</strong> 4.8/5 average rating from beta testers</li>
          </ul>
        </SectionContent>
      </Section>
      
      <Section>
        <SectionTitle>Growth Strategy</SectionTitle>
        <SectionContent>
          <p>
            With our pre-seed funding, we plan to:
          </p>
          <ol>
            <li><strong>Expand to Major US Tourist Destinations:</strong> Launch in 5 major US cities within 12 months, including New York, San Francisco, and Miami</li>
            <li><strong>Enhance AI Capabilities:</strong> Improve our conversational AI to create more natural, engaging character interactions</li>
            <li><strong>Build Content Creation Platform:</strong> Develop tools that allow third parties to create and deploy AR characters</li>
            <li><strong>Establish Strategic Partnerships:</strong> Partner with US tourism boards, museums, and educational institutions</li>
            <li><strong>Develop Revenue Streams:</strong> Implement premium content, B2B licensing, and sponsored characters</li>
          </ol>
        </SectionContent>
      </Section>
      
      <Section>
        <SectionTitle>Our Team</SectionTitle>
        <TeamSection>
          <TeamMember>
            <TeamMemberPhoto>
              <img src="/teamimages/michaelcutajar.jpg" alt="Michael Cutajar" />
            </TeamMemberPhoto>
            <TeamMemberName>Michael Cutajar</TeamMemberName>
            <TeamMemberRole>CEO & Founder</TeamMemberRole>
            <TeamMemberBio>
              Chartered accountant with a passion for tech. Ex CEO of Metaverse Architect (shares sold).
            </TeamMemberBio>
          </TeamMember>
          
          <TeamMember>
            <TeamMemberPhoto>
              <img src="/teamimages/matthudson.png" alt="Matt Hudson" />
            </TeamMemberPhoto>
            <TeamMemberName>Matt Hudson</TeamMemberName>
            <TeamMemberRole>Head of AR</TeamMemberRole>
            <TeamMemberBio>
              6 years experience working in the AR and VR scene, Unity expert.
            </TeamMemberBio>
          </TeamMember>
          
          <TeamMember>
            <TeamMemberPhoto>
              <img src="/teamimages/Kyllian.jpg" alt="Kyllian Le Borgne-Roperch" />
            </TeamMemberPhoto>
            <TeamMemberName>Kyllian Le Borgne-Roperch</TeamMemberName>
            <TeamMemberRole>Lead Developer</TeamMemberRole>
            <TeamMemberBio>
              Part of Decentraland Security Advisory Board and DAO Committee for 5 years (handling 40M+ USD in crypto transactions), worked at the Decentraland Foundation.
            </TeamMemberBio>
          </TeamMember>

          <TeamMember>
            <TeamMemberPhoto>
              <img src="/teamimages/Uman.jpeg" alt="Uman K" />
            </TeamMemberPhoto>
            <TeamMemberName>Uman K</TeamMemberName>
            <TeamMemberRole>Marketing</TeamMemberRole>
            <TeamMemberBio>
              Over 5 years experience in marketing and growth.
            </TeamMemberBio>
          </TeamMember>
        </TeamSection>
      </Section>
      
      <Section>
        <SectionTitle>Investment Opportunity</SectionTitle>
        <SectionContent>
          <p>
            We are currently raising a pre-seed round of $250,000 to fund our expansion to the US market, 
            enhance our technology, and grow our team. This funding will provide approximately 18 months 
            of runway and help us achieve key milestones before a larger seed round.
          </p>
          <p>
            Key use of funds:
          </p>
          <ul>
            <li>Expansion to 5 major US cities (40%)</li>
            <li>Technology development and AI enhancements (30%)</li>
            <li>Team expansion (20%)</li>
            <li>Marketing and user acquisition (10%)</li>
          </ul>
        </SectionContent>
      </Section>
      
      <CTASection>
        <CTATitle>Join Us on This Journey</CTATitle>
        <CTADescription>
          We're looking for strategic investors who share our vision for the future of augmented reality and 
          can bring expertise in technology, tourism, education, or content creation.
        </CTADescription>
        <p style={{ fontSize: '1.3rem', marginTop: '1.5rem' }}>
          Contact us: <a href="mailto:michael@glimpse.wiki" style={{ color: '#FFD700', textDecoration: 'none' }}>michael@glimpse.wiki</a>
        </p>
      </CTASection>
    </InvestorContainer>
  );
}

export default Investors;
